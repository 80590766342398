import React from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import styled from "styled-components";

// Components
import MetaComponent from "../components/MetaComponent";

import { graphql } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  background: white;
`;

const KeyFiLogo = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="KeyFi-Logo" />
      <Wrapper>
        <img src="/docs/KeyFi-logo_68x68.png" alt="KeyFi Logo 68px68px" />
      </Wrapper>
    </ThemeProvider>
  );
};

export default KeyFiLogo;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
